//! COLORS
$black: #000;
$white: #fff;
$search-bar: #eee;

.desktop-navbar{
  display: flex;
  width: 100vw;
  height: 9.2vh;
  background-color: $black;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title-and-logo{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.desktop-logo{
  width: 60px;
  height: 60px;
}

.desktop-title{
  font-family: 'Gabriela', serif;
  color: $white;
}

.search-bar-container{
  display: flex;
  flex-direction: row;
  position: relative;
}

.search-bar{
  padding: 0.5rem;
  font-family: 'Poppins', serif;
  border: none;
  outline: none;
  background-color: #fff;
  width: 50vw;
}

.desktop-search-icon{
  position: absolute;
  color: $black;
  align-self: center;
  margin-left: 94%;
}

.desktop-nav-btns{
  margin-right: 1rem;
  align-items: center;
}

button.desktop-settings-btn{
  font: inherit;
  background-color: #f0f0f0;
  border: 0;
  color: #242424;
  font-family: 'poppins',serif;
  border-radius: 0.5em;
  font-size: 1rem;
  padding: 0.375em 1em;
  font-weight: 600;
  text-shadow: 0 0.0625em 0 #fff;
  box-shadow: inset 0 0.0625em 0 0 #f4f4f4, 0 0.0625em 0 0 #efefef,
    0 0.125em 0 0 #ececec, 0 0.25em 0 0 #e0e0e0, 0 0.3125em 0 0 #dedede,
    0 0.375em 0 0 #dcdcdc, 0 0.425em 0 0 #cacaca, 0 0.425em 0.5em 0 #cecece;
  transition: 0.15s ease;
  cursor: pointer;
}
button.desktop-settings-btn:active {
  translate: 0 0.225em;
  box-shadow: inset 0 0.03em 0 0 #f4f4f4, 0 0.03em 0 0 #efefef,
    0 0.0625em 0 0 #ececec, 0 0.125em 0 0 #e0e0e0, 0 0.125em 0 0 #dedede,
    0 0.2em 0 0 #dcdcdc, 0 0.225em 0 0 #cacaca, 0 0.225em 0.375em 0 #cecece;
}

button.desktop-login-btn{
  font: inherit;
  background-color: #f0f0f0;
  border: 0;
  color: #242424;
  border-radius: 0.5em;
  margin-left: 1rem;
  font-size: 1rem;
  font-family: 'poppins',serif;
  padding: 0.375em 1em;
  font-weight: 600;
  text-shadow: 0 0.0625em 0 #fff;
  box-shadow: inset 0 0.0625em 0 0 #f4f4f4, 0 0.0625em 0 0 #efefef,
    0 0.125em 0 0 #ececec, 0 0.25em 0 0 #e0e0e0, 0 0.3125em 0 0 #dedede,
    0 0.375em 0 0 #dcdcdc, 0 0.425em 0 0 #cacaca, 0 0.425em 0.5em 0 #cecece;
  transition: 0.15s ease;
  cursor: pointer;
}
button.desktop-login-btn:active {
  translate: 0 0.225em;
  box-shadow: inset 0 0.03em 0 0 #f4f4f4, 0 0.03em 0 0 #efefef,
    0 0.0625em 0 0 #ececec, 0 0.125em 0 0 #e0e0e0, 0 0.125em 0 0 #dedede,
    0 0.2em 0 0 #dcdcdc, 0 0.225em 0 0 #cacaca, 0 0.225em 0.375em 0 #cecece;
}

