@media screen and (max-width: 773px) {
    .camera-news-page {
      margin: 0;
      height: 100vh;
      justify-content: center;
      align-items: center;
    }
  
    .camera-news-icon {
      width: 23%;
      height: auto;
      max-width: 100px;
    }
  }
  