@import url(https://fonts.googleapis.com/css?family=Paytone+One:regular);

$white: #fff;
$black: #000000;

.top-header {
  background-color: $black;
  color: $white;
  padding: 1rem;
  height: 57px;
}

.top-logo {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
}

.top-tabs {
  background-color: $black;
}

.top-tabs-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  color: $white;
}

.top-tab-title {
  font-family: "gabriela", serif;
}

.top-tab {
  padding: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  text-decoration: none;
  color: $white;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom-color: $white;
  }

  &.active {
    border-bottom-color: $white;
  }
}

.top-tab-icon {
  width: 13%;
  height: auto;
  max-width: 100px;
}

.top-tabs-header {
  position: fixed;
  z-index: 2;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .top-tabs-header {
    margin: -33% 0 0 -11%;
    width: 30%;
    transform: scale(0.9);
  }

  h1.top-tab-title {
    color: #111;
    font-family: "Darumadrop One", serif;
  }

  .top-tab {
    color: #111;
    font-weight: bold;
    &:hover {
      border-bottom-color: #111;
    }

    &.active {
      border-bottom-color: #111;
    }
  }

  .top-header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: linear-gradient(135deg, #949596, #d5e2e9);
  }

  .top-tabs {
    background: linear-gradient(135deg, #949596, #d5e2e9);
  }

  .top-tab-title {
    font-family: "poppins", serif;
  }

  .top-tab-icon {
    width: 15%;
  }
}

@media screen and (min-width: 1280px) {
  .top-tabs-header {
    width: 24%;
    transform: scale(1.01);
    margin-top: -29%;
    margin-left: -11%;
  }

  h1.top-tab-title {
    color: #111;
    font-family: "Darumadrop One", serif;
  }
}

@media screen and (min-width: 1280px) and (min-height: 950px) {
  .top-tabs-header {
    transform: scale(0.93);
    margin-top: -33%;
    margin-left: -10%;
  }
}

@media screen and (min-width: 1366px) and (min-height: 768px) {
  .top-tabs-header {
    width: 24%;
    transform: scale(1.05);
    margin-top: -27%;
    margin-left: -12%;
  }
}

@media screen and (min-width: 1440px) {
  .top-tabs-header {
    width: 23%;
    transform: scale(1.07);
    margin-top: -26%;
    margin-left: -12%;
  }

  h1.top-tab-title {
    color: #111;
    font-family: "Darumadrop One", serif;
  }
}

@media screen and (min-width: 1920px) {
  .top-tabs-header {
    width: 24%;
    margin-top: -26%;
    transform: scale(1.09);
  }

  h1.top-tab-title {
    color: #111;
    font-family: "Darumadrop One", serif;
  }
}
