@media screen and (max-width: 773px) {
  .home-page {
    margin: 0;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .header-logo {
    max-width: 100%;
    height: 55px;
  }
}
