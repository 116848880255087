$black: #000000;
$white: #fff;

.custom-popup-world {
  .leaflet-popup-content-wrapper {
    background-color: $black;
  }

  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      background-color: $black;
    }
  }
}

.loading-news {
  font-family: "poppins", serif;
  white-space: nowrap;
  color: $white;
}

.city {
  font-family: "poppins", serif;
  font-weight: bold;
  color: $white;
}

.world-headline {
  font-family: "poppins", serif;
  color: $white;
}

.maps-cont {
  position: relative;
  z-index: 1;
}

.map-screen {
  width: 100%;
  height: 100vh;
}

@media screen and (min-width:1024px) {
  .map-screen{
    height: 57vh;
    
    border-radius: 1rem;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
}