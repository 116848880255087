$white: #fff;
$gray-light: #eee;
$gray-text: rgb(197, 196, 196);
$black: #000000;

.contact-box,
.contact-section,
.illustration-section,
.form,
input.contact-form-name,
input.contact-form-email,
textarea.contact-form-message,
h4.contact-box-second-head,
button.submit-btn,
button.submit-btn-disabled,
img.contact-us-illustration,
.contact-info,
.location-dot,
.first-inbox,
.second-inbox {
  font-family: "poppins", serif;
  border: none;
  outline: none;
}

.contact-box {
  display: flex;
  flex-direction: row;
  background-color: $white;
  height: auto;
  padding: 5%;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  justify-content: center;
  align-content: center;
  margin: 1% 0;
}

.contact-section { flex: 2; }

.illustration-section { flex: 1; display: flex; flex-direction: column; }

.form { display: flex; flex-direction: column; padding-top: 5%; }

input.contact-form-name,
input.contact-form-email,
textarea.contact-form-message {
  background-color: $gray-light;
  padding: 2%;
  margin-bottom: 3%;
}

textarea.contact-form-message { height: 150px; }

h4.contact-box-second-head { color: $gray-text; }

button.submit-btn,
button.submit-btn-disabled {
  color: $white;
  font-size: 1rem;
  background-color: $black;
  padding: 2%;
  font-weight: bold;
  text-align: center;
}

button.submit-btn-disabled { opacity: 0.5; }

img.contact-us-illustration {
  width: 100%;
  height: 50%;
  margin-bottom: 20%;
}

.contact-info { white-space: nowrap; }

.location-dot,
.first-inbox,
.second-inbox {
  padding: 5% 5% 0 0;
}

/* Media queries */
@media screen and (min-width: 1080px) and (max-width: 1920px) {
  .contact-box {
    width: 50%;
    margin-top: 7%;
    margin-left: 25%;
    transform: scale(1.3);
  }
  .contact-info { margin-left: 20%; transform: scale(1.3); }
}

@media screen and (min-width: 768px) and (max-width: 1366px) {
  .contact-box { width: 60%; transform: scale(1); margin-top: -1%; }
  .contact-info { margin-left: 15%; }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contact-box { width: 65%; transform: translate(27%, 0%); }
  .contact-info { margin-left: 10%; }
}
