@media screen and (max-width: 773px) {
    .SecondTrending-page {
      margin: 0;
      height: 100vh;
      justify-content: center;
      align-items: center;
    }

    .war-icon {
      width: 25%;
      height: auto;
      max-width: 100px;
    }
  }
  