@media screen and (max-width: 773px) {
    .fashion-page {
      margin: 0;
      height: 100vh;
      justify-content: center;
      align-items: center;
    }

    .fashion-icon {
      width: 25%;
      height: auto;
      max-width: 100px;
    }
  }
  