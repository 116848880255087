@media screen and (max-width: 773px) {
    .education-page {
      margin: 0;
      height: 100vh;
      justify-content: center;
      align-items: center;
    }

    .book-icon {
      width: 30%;
      height: auto;
      max-width: 100px;
    }
  }
  