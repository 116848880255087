.d-news-timer {
  display: flex;
  overflow-x: hidden;
}

.d-news-timer-card{
  scroll-snap-align: start;
  flex: 0 0 auto;
  width: 100%;
  padding: 0 10px;
  transition: opacity 0.3s ease;
}

.d-news-timer-card.active{
  opacity: 1;
}

.d-news-timer-option-share-btn-cont {
  display: flex;
  flex-direction: row;
}

.d-select select {
  border: #eee;
  outline: none;
  padding: 0.3rem;
  width: 199%;
  background-color: #eee;
  margin-top: 36%;
  margin-left: 2%;
}

p.d-news-timer-paragraph {
  font-size: 1.2rem;
  font-family: "Vollkorn", serif;
  font-weight: 400;
  color: #3f3f55;
  text-align: left;
}

p.d-news-timer-paragraph::after {
  content: "\201D";
  display: inline;
  color: #999;
}

p.d-news-timer-paragraph::before {
  content: "\201C";
  display: inline;
  color: #999;
}

.d-news-timer-article-img-container {
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 7%;
}

.d-news-timer-article-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.d-share-buttons {
  display: flex;
  flex-direction: row;
  transform: sclae(1.4);
  margin-left: 30%;
  margin-top: 7%;
}

span.d-timer-card-date {
  font-family: "poppins", serif;
  font-weight: bold;
  color: #ccc;
  font-size: 0.8rem;
  margin-left: 12.8%;
}

.d-news-timer-btns {
  display: flex;
  flex-direction: row;
  position: fixed;
  margin-top: 68%;
  margin-left: 17%;
  transform: scale(1.3);
}

.d-read-in-btn {
  white-space: nowrap;
  border: none;
  outline: none;
  height: 42px;
  font-weight: bold;
  font-size: 0.7rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
  color: #fff;
  background-color: #111;
  font-family: "poppins", serif;
}

button.d-stop-btn {
  white-space: nowrap;
  border: none;
  outline: none;
  width: 98px;
  height: 42px;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
  color: #fff;
  background-color: #111;
  font-family: "poppins", serif;
  cursor: pointer;
}

.d-duration.d-selected{
  background-color: #111;
  color: #fff;
}

.d-duration {
  padding: 3px 16px;
  margin-right: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: "poppins", serif;
  font-size: 0.8rem;
  margin-left: 5%;
}

@media screen and (min-width: 1024px) {
  .d-news-timer-card {
    position: absolute;
    top: 21%;
    left: 24%;
    transform: translate(0%, 0%);
    width: 440px;
    padding: 5%;
    box-sizing: border-box;
    background: #fff;
  }

  .d-news-timer-btns {
    display: flex;
    flex-direction: row;
    position: fixed;
    margin-top: 33%;
    margin-left: 12%;
    transform: scale(1.2);
  }
}

@media screen and (min-width: 1280px) {
  .d-news-timer-card {
    position: absolute;
    top: 110%;
    left: 2%;
    transform: translate(0%, 0%);
    width: 440px;
    padding: 9%;
    box-sizing: border-box;
    background: #fff;
  }
  .d-news-timer-btns {
    display: flex;
    flex-direction: row;
    position: fixed;
    margin-top: 73%;
    margin-left: 14.9%;
    transform: scale(1.37);
  }
}

@media screen and (min-width: 1280px) and (min-height: 950px) {
  .d-news-timer-btns {
    margin-top: 76%;
  }
}

@media screen and (min-width: 1366px) and (min-height: 768px) {
  .d-news-timer-card {
    position: absolute;
    top: 115%;
    left: 3%;
    transform: translate(0%, 0%);
    transform: scale(1.2);
    width: 415px;
    padding: 9%;
    box-sizing: border-box;
    background: #fff;
  }

  .d-news-timer-btns {
    display: flex;
    flex-direction: row;
    position: fixed;
    margin-top: 83%;
    margin-left: 12%;
    transform: scale(1.5);
  }
}

@media screen and (min-width: 1440px) {
  .d-news-timer-card {
    position: absolute;
    top: 110%;
    left: 5%;
    transform: translate(0%, 0%);
    width: 440px;
    padding: 9%;
    box-sizing: border-box;
    background: #fff;
  }
  .d-news-timer-btns {
    display: flex;
    flex-direction: row;
    position: fixed;
    margin-top: 68%;
    margin-left: 17%;
    transform: scale(1.3);
  }
}

@media screen and (min-width: 1920px) {
  .d-news-timer-card {
    position: absolute;
    top: 110%;
    left: 10%;
    transform: translate(0%, 0%);
    width: 440px;
    padding: 9%;
    box-sizing: border-box;
    background: #fff;
    transform: scale(1);
  }
  .d-news-timer-btns {
    display: flex;
    flex-direction: row;
    position: fixed;
    margin-top: 57%;
    margin-left: 19.9%;
    transform: scale(1.3);
  }
}
