@media screen and (max-width: 773px) {
    .entertainment-page {
      margin: 0;
      height: 100vh;
      justify-content: center;
      align-items: center;
    }

    .cinema-icon {
      width: 18%;
      height: auto;
      max-width: 100px;
    }
  }
  