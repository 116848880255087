//! COLORS
$black: #000000;
$white: #fff;
$card-bg: #f5f5f5;
$card-color: #272727;
$card-shadow: rgba(100, 100, 100, 0.4);
$light-grey: #eee;
$green: #03c988;
$orange: #f2613f;
$blue: #387adf;

//! FONT FAMILY
$poppins: "poppins";

.camera-news-cont {
  padding: 30% 0 0 -80%;
}

.camera-container {
  padding: 55% 9% 0 0;
}

img.uploaded-img {
  width: 50%;
  height: 20px;
}

.webcam-video {
  width: 50%;
  height: 30%;
  margin: -25% 0 0 35%;
}

img.captured-img {
  width: 50%;
  height: 30%;
}

p.browsing-artc {
  color: $black;
  font-family: $poppins, serif;
  margin: 12% 0 0 13%;
}

.capture-btn {
  color: $white;
  border: none;
  outline: none;
  font-family: $poppins, serif;
  background-color: $black;
  border-radius: 0.3rem;
  padding: 0.3rem;
  font-weight: bold;
  font-size: 1rem;
  margin: 5% 0 0 35%
}

.disabled-capture {
  color: $white;
  border: none;
  outline: none;
  font-family: $poppins, serif;
  background-color: $black;
  border-radius: 0.3rem;
  padding: 0.3rem;
  font-weight: bold;
  font-size: 1rem;
  opacity: 0.5;
}

.article-card {
  min-width: 200px;
  max-width: 480px;
  height: auto;
  margin-top: 15%;
  background: $card-bg;
  color: $card-color;
  padding: 20px;
  box-shadow: 0px 7px 24px $card-shadow;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 7%;
  font-size: 0.9rem;
  font-family: $poppins, serif;
}

button.next-btn {
  border: none;
  outline: none;
  padding: 0.3rem;
  color: $white;
  font-weight: bold;
  font-family: $poppins, serif;
  background-color: $blue;
  border-radius: 0.3rem;
  margin: 3% 0 0 85%;
}

button.question-btn {
  border: none;
  outline: none;
  padding: 0.3rem;
  color: $white;
  font-weight: bold;
  font-family: $poppins, serif;
  background-color: $orange;
  border-radius: 0.3rem;
  margin: -3% 0 0 15%;
  white-space: nowrap;
}

input.answer-input-btn {
  border: none;
  outline: none;
  background-color: $light-grey;
  padding: 0.3rem;
  border-radius: 0.3rem;
  width: 75%;
  margin: 3% 3% 0 6%;
  font-family: $poppins, serif;
}

button.done-btn {
  border: none;
  outline: none;
  background-color: $green;
  color: $white;
  padding: 0.3rem;
  border-radius: 0.3rem;
  font-family: $poppins, serif;
  font-weight: bold;
}
