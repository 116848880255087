$black: #000000;
$white: #fff;

.custom-popup {
  .leaflet-popup-content-wrapper {
    background-color: $black;
  }

  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      background-color: $black;
    }
  }
}

.loading-news {
  font-family: "poppins", serif;
  white-space: nowrap;
  color: $white;
}

.city {
  font-family: "poppins", serif;
  font-weight: bold;
  color: $white;
}

.national-headline {
  font-family: "poppins", serif;
  color: $white;
}

.maps-cont {
  position: relative;
  z-index: 1;
}

.map-screen {
  width: 100%;
  height: 100vh;
}

.map-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.d-use-button {
display: none;
}

.d-use-button:hover {
  cursor: pointer;
  color: #4e4e4e;
}

@media screen and (min-width: 1024px) {
  .map-screen {
    height: 52vh;
    border-radius: 1rem;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;
    pointer-events: none;
    opacity: 0.5;
  }

  .d-use-button {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    font-size: 16px;
    background: linear-gradient(135deg, #949596, #d5e2e9);
    color: #111;
    border: none;
    font-weight: bold;
    white-space: nowrap;
    font-family: "poppins", serif;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 999;
  }
}

@media screen and (min-width: 1280px) {
  .d-use-button{
    transform: scale(1.3);
    margin-left: -9%;
    margin-top: -10%;
  }
}

@media screen and (min-width: 1366px) and (min-height: 768px) {
  .d-use-button{
    transform: scale(1.3);
    margin-left: -9%;
    margin-top: -10%;
  }
}

@media screen and (min-width: 1440px) {
  .d-use-button{
    transform: scale(1.3);
    margin-left: -9%;
    margin-top: -10%;
  }
}

@media screen and (min-width: 1920px) {
  .d-use-button{
    transform: scale(1.3);
    margin-left: -9%;
    margin-top: -10%;
  }
}
