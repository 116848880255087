.main{
  overflow-y: scroll;
}


.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: auto;
  margin-top: 100px;
}

.chat-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.chat-box {
  width: 100%;
  height: 300px;
  overflow-y: auto;
  border: 2px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 100px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.chat-message {
  background-color: #007bff;
  color: #fff;
  padding: 8px 12px;
  border-radius: 15px;
  max-width: 70%;
  align-self: flex-start;
}

.chat-input-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  gap: 10px;
}

.chat-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.chat-button {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;
}

.chat-button:hover {
  background-color: #0056b3;
}
