//! COLORS
$white: #fff;
$grey: #ccc;
$green: #03C988;
$box-shadow: rgba(0, 0, 0, 0.1);

//! FONT FAMILY
$poppins: 'poppins';

.custom-done-alert {
    width: 250px;
    max-width: 250px;
    height: 30%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0 2px 5px $box-shadow;
    z-index: 9999;
  
    &-content {
      text-align: center;
      p.done-alert-popup-msg {
        margin-bottom: 10px;
        font-family: $poppins, serif;
      }
      button.done-alert-close-btn {
        padding: 5px 10px;
        border: none;
        background-color: $green;
        color: $white;
        border-radius: 3px;
        cursor: pointer;
        font-weight: bold;
       font-family: $poppins, serif;
      }
    }
  }
  