$w: 30.6rem;
$h: 54rem;
$headerH: 6rem;
$cardW: 295px;
$cardH: 420px;
$cardTopH: 20.5rem;
$imgSize: 10rem;

$newspaper-theme: linear-gradient(
  to bottom,
  #f2f2f2,
  #e0e0e0,
  #cccccc,
  #b8b8b8,
  #a3a3a3
);
$background: #f5f5f5;
$orange: #fd7905;
$red: #ff6666;
$green: #03c988;
$grey: #b6bbc4;
$white: #fff;
$blue: #525ceb;
$warm-gray: #111111;
$black: #000000;
$background-box-shadow: rgba(0, 0, 0, 0.9);
$card-container-background: #fafafa;
$card-container-box-shadow-1: rgba(0, 0, 0, 0.3);
$card-container-box-shadow-2: linear-gradient(to bottom, #f6f6f6, #e1e1e1) inset;

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  box-shadow: inset 0 7px 70px $background-box-shadow;
  background: $background;
  overflow: hidden;
  margin: 0;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .news {
    &__card-cont {
      margin: 1% 0 0 -12%;
    }
  }
}

@media screen and (min-width: 1280px) {
  .news {
    &__card-cont {
      margin: 16.2rem 0 0 -3.5rem;
    }
  }
}

@media screen and (min-width: 1920px) {
  .news {
    &__card-cont {
      margin-top: 41%;
      margin-left: -15%;
    }
  }
}

.news {
  &__card-cont {
    background: $card-container-background;
    box-shadow: 0 0 10px $card-container-box-shadow-1,
      0 0 300px 25px $card-container-box-shadow-2;
    width: $cardW;
    height: $cardH;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 24px;
    position: fixed;
  }

  &__card {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transform-origin: 50% 100%;
    max-height: 420px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;

    &.reset {
      transition: transform 1s;
      transform: translateY(0) !important;

      .news__card__choice {
        transition: opacity 1s;
        opacity: 0 !important;
      }
    }

    &.inactive {
      transition: transform 0.5s ease;
    }

    &.to-left {
      transform: translateY(30rem) rotate(30deg) !important;
    }

    &.to-right {
      transform: translatey(30rem) rotate(30deg) !important;
    }

    &.below {
      z-index: 1;
    }

    &__choice {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: -1;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:before {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
      }

      &:after {
        width: 4rem;
        height: 1.5rem;
        border-bottom: none;
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
      }

      &.m--reject {
        background: $newspaper-theme;
      }

      &.m--like {
        background: $newspaper-theme;

        &:after {
          transform: scaleY(-1);
        }
      }
    }

    &__drag {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__tip {
    text-align: center;
    font-size: 2.2rem;
  }
}

h3 {
  margin: 3.8% 0 0;
  text-align: center;
  width: 100%;
  font-size: 1rem;
}

.news__card-cont:before,
.news__card-cont:after {
  content: "";
  background: linear-gradient(
    to bottom,
    #f2f2f2,
    #e0e0e0,
    #cccccc,
    #b8b8b8,
    #a3a3a3
  );
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2),
    inset 0 0 300px
      linear-gradient(to bottom, #f2f2f2, #e0e0e0, #cccccc, #b8b8b8, #a3a3a3);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  transition: 0.5s;
}

.news__card-cont:before {
  left: -5px;
  top: 2px;
  transform: rotate(-1.5deg);
}
.news__card-cont:after {
  right: -3px;
  top: 0px;
  transform: rotate(2.4deg);
}

img.article-img {
  width: 270px;
  height: 168px;
  margin: 5% 0 0 4%;
  border-radius: 0.5rem;
  border: 3px solid $grey;
}

p.news__card__we {
  margin: 8% 0 10% 0;
  font-family: "Bricolage Grotesque", serif;
  font-size: 1rem;
  max-width: $cardW;
  height: 30%;
  max-height: 30%;
  overflow: hidden;
}

p.news__card__we__big {
  margin: 5% 0 10% 0;
  font-family: "Bricolage Grotesque", serif;
  font-size: 1rem;
  max-width: $cardW;
  height: 30%;
  max-height: 30%;
  overflow: hidden;
}

.link-fact-date-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  bottom: 10px;
  position: fixed;
}

.link-fact-cont {
  display: flex;
  flex-direction: row;
}

.link-to-source {
  text-decoration: none;
  color: $white;
  padding: 0.2rem 0.3rem 0.3rem 0.3rem;
  border-radius: 0.3rem;
  font-weight: 700;
  font-size: 0.7rem;
  white-space: nowrap;
  background-color: $blue;
  font-family: "poppins", serif;
}

button.fact-btn {
  background-color: $orange;
  border: none;
  outline: none;
  color: $white;
  padding: 0.2rem;
  border-radius: 0.3rem;
  font-weight: 700;
  font-size: 0.7rem;
  margin: 0 0 0 3%;
  white-space: nowrap;
  font-family: "poppins", serif;
  cursor: pointer;
}

button.fact-btn-green {
  background-color: $green;
  border: none;
  outline: none;
  color: $white;
  font-size: 0.7rem;
  padding: 0.2rem;
  border-radius: 0.3rem;
  font-weight: 700;
  margin: 0 0 0 3%;
  font-family: "poppins", serif;
}

button.fact-btn-red {
  background-color: $red;
  border: none;
  outline: none;
  color: $white;
  padding: 0.2rem;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  font-weight: 700;
  margin: 0 0 0 3%;
  font-family: "poppins", serif;
}

p.news_card_date {
  color: $warm-gray;
  margin-top: 2%;
  font-family: "poppins", serif;
  font-size: 0.61rem;
  font-weight: 700;
  white-space: nowrap;
  justify-self: flex-end;
  right: 5%;
  position: fixed;
}

.load-more-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  outline: none;
  font-weight: bold;
  font-family: "bricolage grotesque", serif;
  border-radius: 1rem;
  background-color: $black;
  color: $white;
  cursor: pointer;
}

.completed-articles-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  outline: none;
  font-weight: bold;
  font-family: "bricolage grotesque", serif;
  border-radius: 1rem;
  background-color: $green;
  color: $white;
}

.speaker {
  margin: 2% 0 0 5%;
  cursor: pointer;
}
