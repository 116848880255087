@media screen and (max-width: 773px) {
  .technology-page {
    margin: 0;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .technology-icon {
    width: 22%;
    height: auto;
    max-width: 100px;
  }
}
