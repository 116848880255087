@import url(https://fonts.googleapis.com/css?family=Darumadrop+One:regular);
@import url(https://fonts.googleapis.com/css?family=Amaranth:regular,italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Lexend+Deca:100,200,300,regular,500,600,700,800,900);

// COLORS
$black: #111;
$white: #fff;
$bgc: #f9f9f9;
$bgc-gradient: linear-gradient(135deg, #949596, #d5e2e9);

// Font Families
$font-1: "Amaranth";
$font-2: "Darumadrop One";
$font-3: "Lexend Deca";

.left-sidebar {
  margin: -23% 0 0 -18%;
}

.d-card-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 250px;
  background: $bgc-gradient;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.d-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.d-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h2.d-caption {
  margin: 5% 0 0 0;
  font-family: $font-2, serif;
}

.d-trending-btns-cont {
  display: flex;
  flex-direction: column;
}

.d-trending-item-1-and-icon-cont,
.d-trending-item-2-and-icon-cont,
.d-trending-item-3-and-icon-cont,
.d-feedback-btn-cont {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin: 7% 0 0 0;
}

button.d-trending-item-1,
button.d-trending-item-2,
button.d-trending-item-3 {
  padding: 12px 34px;
  border-radius: 10px;
  border: 0;
  background: $bgc-gradient;
  letter-spacing: 1.5px;
  font-size: 1.4rem;
  color: $black;
  cursor: pointer;
  font-weight: bold;
  font-family: $font-1, serif;
  transition: all 0.3s ease-in-out;
}

.d-trend-up-icon {
  color: $black;
  padding: 3%;
  align-self: center;
  font-size: 1.3rem;
}

button.d-feedback-btn {
  padding: 0.5rem;
  background: $bgc-gradient;
  text-align: center;
  width: 83%;
  border: none;
  outline: none;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-family: $font-3, serif;
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.d-feedback-btn:hover {
  transform: scale(1.1);
}

button.d-trending-item-1:hover {
  transform: scale(1.1);
}

button.d-trending-item-2:hover {
  transform: scale(1.1);
}

button.d-trending-item-3:hover {
  transform: scale(1.1);
}

@media screen and (min-width: 1280px) {
  .left-sidebar {
    transform: scale(1.3);
    margin-top: -8%;
    margin-left: 3%;
  }

  button.d-feedback-btn {
    width: 95%;
  }
}

@media screen and (min-width: 1280px) and (min-height: 950px) {
  .left-sidebar {
    transform: scale(1.3);
    margin-top: -59%;
    margin-left: -3%;
  }
}

@media screen and (min-width: 1440px) {
  .left-sidebar {
    transform: scale(1.5);
    margin-top: -20%;
    margin-left: -1%;
  }

  button.d-feedback-btn {
    width: 95%;
  }
}

@media screen and (min-width: 1920px) {
  .left-sidebar {
    transform: scale(1.7);
    margin-top: -41%;
  }
}

