.not-found{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 100vh;
}

.not-found img{
    width: 100%;
    height: 50%;
    margin-top: 0%;
}

.not-found p{
    margin: 3rem 0 0 0;
}