@media screen and (max-width: 773px) {
  .news-timer-page {
    margin: 0;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .news-timer-icon {
    max-width: 23%;
    height: 46px;
   
  }
}
