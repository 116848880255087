//! COLORS
$white: #fff;
$grey: #aaaaaa;
$shadow: rgba(100, 100, 111, 0.2);

.first-feature {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 69vh;
}

.feature-box {
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 63vh;
  box-shadow: $shadow 0px 7px 29px 0px;
}

.feature-details {
  display: flex;
  flex-direction: column;
  margin: 3%;
}

h2.feature-title{
  font-size: 2vw;
}

p.feature-description {
  font-family: "poppins", serif;
  text-align: justify;
  width: 95%;
  margin-top: 2.9%;
  font-size: 1.66vw;
}

img.first-mockup {
  width: 27vw;
  height: 58vh;
  margin: 3% 0 0 -5%;
}

img.second-mockup{
  width: 33vw;
  height: 55vh;
  margin: 3vh 2vw 0 3vw;
}

img.third-mockup{
  width: 25vw;
  height: 55vh;
  margin: 4vh 3vw 0 0;
}

img.fourth-mockup{
  width: 25vw;
  height: 55vh;
  margin: 4vh 3vw 0 0;
}

.right-arrow,
.left-arrow {
  margin: 0 20px;
  border: 1px solid $grey;
  padding: 1%;
  border-radius: 100%;
  cursor: pointer;
}
