//! COLORS
$black: #000000;
$grey: #a3a3a3;
$white: #fff;

.bottom-tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $black;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
  z-index: 2;

  .tab-item {
    text-decoration: none;
    color: $grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 5px;
    flex: 1;
    transition: color 0.3s ease;

    &.active {
      color: $white;
    }

    &:nth-child(3) {
      svg {
        font-size: 24px;
      }
    }
  }
}


@media screen and (min-width: 1024px) {
  .bottom-tabs {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .bottom-tabs {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .bottom-tabs {
    display: none;
  }
}

@media screen and (min-width: 1900px) {
  .bottom-tabs {
    display: none;
  }
}



