@import url(https://fonts.googleapis.com/css?family=Lexend+Deca:100,200,300,regular,500,600,700,800,900);

.d-center-area {
  display: flex;
  flex-direction: column;
  margin-left: -35%;
  margin-top: -15%;
}

.d-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -44% 0 0 12%;
  width: 80%;
  z-index: 1;
}

button.d-home-tab,
button.d-world-tab,
button.d-news-timer-tab {
  font-size: 1rem;
  border: none;
  outline: none;
  padding: 0.3rem 1.9rem 0.3rem 1.9rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-weight: bold;
  font-family: "Poppins", serif;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #949596, #d5e2e9);
}

button.d-home-tab:hover,
button.d-world-tab:hover,
button.d-news-timer-tab:hover,
button.d-home-tab-for-timer:hover,
button.d-world-tab-for-timer:hover,
button.d-news-timer-tab-for-timer:hover {
  cursor: pointer;
  color: #4e4e4e;
  background: linear-gradient(135deg, #949596, #d5e2e9);
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

button.d-home-tab:active,
button.d-world-tab:active,
button.d-news-timer-tab:active,
button.d-home-tab-for-timer:active,
button.d-world-tab-for-timer:active,
button.d-news-timer-tab-for-timer:active {
  transform: scale(0.98);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

button.d-home-tab-for-timer,
button.d-world-tab-for-timer,
button.d-news-timer-tab-for-timer {
  font-size: 1rem;
  border: none;
  outline: none;
  padding: 0.3rem 1.9rem 0.3rem 1.9rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-weight: bold;
  font-family: "Poppins", serif;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #949596, #d5e2e9);
}

.d-menu-container {
  display: flex;
  flex-direction: column;
  margin: 4% 0 -110% 75%;
  width: 29%;
  color: #111;
  background: linear-gradient(135deg, #949596, #d5e2e9);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.d-category-icon-cont {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #fff;
  width: 9rem;
  padding: 0 1rem 0.2rem 0;
}

h3.d-category {
  align-self: center;
  font-family: "Darumadrop One", serif;
  font-size: 1.2rem;
}

.d-menu-icon {
  align-self: center;
  margin: 0.4% 0 0 9%;
}

.d-technology,
.d-entertainment,
.d-sports,
.d-health,
.d-science,
.d-fashion,
.d-education,
.d-business {
  text-decoration: none;
  padding: 1rem;
  font-family: "poppins", serif;
  color: #111;
  margin-top: 1%;
  font-size: 0.9rem;
  border-radius: 1rem;

  &:hover {
    cursor: pointer;
    color: #e2e1e1;
  }
}

.d-scroll-arrow {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #111;
  cursor: pointer;
  padding: 0.5rem;
  &:hover {
    color: #555;
  }
}
.d-menu-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.d-arrow-up {
  font-size: 1rem;
}

.d-arrow-down {
  font-size: 1rem;
}

@media screen and (min-width: 1024px) {
  .d-tabs-for-timer {
    margin: -44% 0 0 11%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
  }
}

@media screen and (min-width: 1280px) {
  .d-tabs-for-timer {
    margin: 70% 0 0 11%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    transform: scale(1.2);
  }
  .d-center-area {
    transform: scale(1.3);
    margin-top: -175%;
    margin-left: -10%;
  }

  .d-tabs {
    margin-top: 115%;
    margin-left: 2%;
    width: 110%;
    margin-bottom: 2%;
  }

  .d-menu-container {
    margin: 0 0 0 77%;
    width: 34.5%;
  }
}

@media screen and (min-width: 1280px) and (min-height: 950px) {
  .d-center-area {
    margin-top: -205%;
    transform: scale(1.4);
  }

  .d-tabs-for-timer {
    margin: 76% 0 0 11%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    transform: scale(1.2);
  }
}

@media screen and (min-width: 1366px) and (min-height: 768px) {
  .d-center-area {
    margin-top: -170%;
  }

  .d-tabs-for-timer {
    margin: 68% 0 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    transform: scale(1.2);
  }
}

@media screen and (min-width: 1440px) {
  .d-tabs-for-timer {
    margin: 64% 0 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    transform: scale(0.99);
  }
  .d-center-area {
    transform: scale(1.5);
    margin-top: -200%;
    margin-left: -20%;
  }

  .d-tabs {
    margin-top: 105%;
    margin-left: 5%;
    width: 90%;
    margin-bottom: 2%;
  }

  .d-menu-container {
    margin: 0 0 0 75%;
    width: 32.5%;
  }
}

@media screen and (min-width: 1920px) {
  .d-center-area {
    transform: scale(1.8);
    margin-top: -215%;
    margin-left: -50%;
  }

  .d-tabs {
    margin-top: 77%;
    margin-left: 3%;
    width: 80%;
    margin-bottom: 2%;
  }

  .d-tabs-for-timer {
    margin: 44% 0 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    transform: scale(0.99);
  }

  .d-menu-container {
    margin: 3% 0 0 65%;
    width: 25.3%;
  }
}
