.desktop-home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    /*overflow-y: scroll;
    overflow-x: hidden;*/
    background: #d4d4d4;
  }
  
  /*.page {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    padding: 40px;
    justify-content: space-between;
  
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
*/

.main-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 5%;
}

